export const UserRoles = {
    ADMIN: 'Admin',
    PROVIDER: 'Provider',
    DEVELOPER: 'Developer',
    MA: 'MA',
    MD: 'MD',
    OFFICE_MGR: 'Office Mgr',
    FRONT_OFFICE: 'Front Office',
    ECOMMERCE: 'ECommerce',
    MSO_PROVIDER: 'MSO Provider',
    HEALTH_COACH: 'Health Coach',
    ECOMMERCE_ANALYST: 'ECommerce Analyst',
};

export const Menus = {
    dashboard: 'Dashboard',
    admin: 'Admin',
    contacts: 'Contacts',
    survey: 'Survey',
    forms: 'Forms',
    home: 'Home',
    dataDictionary: 'Data Dictionary',
    menuSetup: 'Menu Setup',
};

export const SubMenus = {
    alert: 'Alerts',
    users: 'Users',
    clients: 'Clients',
    survey: 'Surveys',
    surveyResponse: 'Survey Response',
    patient: "Patient",
    supportTicket: "Support Ticket",
};
