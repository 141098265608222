<div class="page-header-wrapper">
    <mat-card class="mat-elevation-z5">
        <div class="page-header-container">
            <div class="inner-header-container">
                <h1 class="header" *ngIf="!isEditTitle">{{pageTitle}}</h1>
                <input matInput type="text" [(ngModel)]="pageTitle" *ngIf="isEditTitle">
                <i class="fa fa-pencil" aria-hidden="true" (click)="isEditTitle = !isEditTitle"
                    *ngIf="isTitleEditable && !isEditTitle"></i>
                <i class="fa fa-close" *ngIf="isTitleEditable && isEditTitle" (click)="isEditTitle = !isEditTitle"></i>
            </div>

            <div class="btn-div">
                <button *ngFor="let button of buttons" mat-raised-button
                    [ngStyle]="{'background-color':button.butnBackgroudColor, 'color': button.butnTextColor}"
                    [ngClass]="'mat-elevation-z3 ' + button.class" (click)="onButtonClick(button.butnAction)"
                    [disabled]="button.disabled">
                    {{button.butnName}}
                </button>
                <!-- <button *ngFor="let button of buttons" mat-raised-button [ngClass]="button.class"
                    (click)="onButtonClick(button.action)" [disabled]="button.disabled">
                    {{button.name}}
                </button> -->
            </div>
        </div>
    </mat-card>
</div>