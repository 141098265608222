import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2CompleterModule } from "ng2-completer";
import { QuillModule } from 'ngx-quill';
import { OverlayModule } from '@angular/cdk/overlay';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { OneTimeBindingDirective } from './directives/one-time-binding.directive';
import { CsvUploadComponent } from './components/csv-upload/csv-upload.component';
import { CustomPanelComponent } from './components/custom-panel/custom-panel.component';
import { IndexConfigComponent } from './components/pages/index-config/index-config.component';
import { ViewConfigComponent } from './components/pages/view-config/view-config.component';
import { EditConfigComponent } from './components/pages/edit-config/edit-config.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { BaseConfigComponent } from './components/pages/base-config/base-config.component';
import { SectionGridComponent } from './components/section-grid/section-grid.component';
import { JsonEditrComponent } from './components/json-editor/json-editr.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { HeaderComponent } from './components/header/header.component';
import { AlertPopupComponent } from './components/popups/alert-popup/alert-popup.component';
import { DashboardConfigComponent } from './components/pages/dashboard-config/dashboard-config.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DashboardMenuComponent } from './components/pages/dashboard-menu/dashboard-menu.component';

@NgModule({
  declarations: [
    SpinnerComponent,    
    CustomTooltipDirective,
    OneTimeBindingDirective,
    CsvUploadComponent,
    CustomPanelComponent,
    IndexConfigComponent,
    ViewConfigComponent,
    EditConfigComponent,
    ProfileComponent,
    BaseConfigComponent,
    SectionGridComponent,
    JsonEditrComponent,
    HeaderComponent,
    AlertPopupComponent,
    DashboardConfigComponent,
    BarChartComponent,
    DashboardMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    MaterialModule,
    QuillModule,
    OverlayModule,
    NgJsonEditorModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    QuillModule,
    Ng2CompleterModule,
    SpinnerComponent,    
    CustomTooltipDirective,
    OverlayModule,
    OneTimeBindingDirective,
    CsvUploadComponent,
    CustomPanelComponent,
    IndexConfigComponent,
    ViewConfigComponent,
    EditConfigComponent,
    SectionGridComponent,
    NgJsonEditorModule,
    HeaderComponent,
    JsonEditrComponent
  ],
  providers:[
  ]
})
export class SharedModule { }
